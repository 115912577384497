import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

import { PdfViewPropsType } from '../../types'

export const PdfView: React.FC<PdfViewPropsType> = (props) => {

  return (
    <>
      <div
        ref={props.viewerRef}
      >
        <Document
          file={props.pdfUrl}
          onLoadSuccess={props.onDocumentLoadSuccess}
        >
          <Page
            pageNumber={props.currentPage}
          />
        </Document>
      </div>
    </>
  )
}